.CodeMirror {
  height: 300px;
  color: #000;
  direction: ltr;
  font-family: monospace;
}

.CodeMirror-lines {
  padding: 4px 0;
}

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px;
}

.CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  background-color: #fff;
}

.CodeMirror-gutters {
  white-space: nowrap;
  background-color: #f7f7f7;
  border-right: 1px solid #ddd;
}

.CodeMirror-linenumber {
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
  padding: 0 3px 0 5px;
}

.CodeMirror-guttermarker {
  color: #000;
}

.CodeMirror-guttermarker-subtle {
  color: #999;
}

.CodeMirror-cursor {
  width: 0;
  border-left: 1px solid #000;
  border-right: none;
}

.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}

.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  background: #7e7;
  border: 0 !important;
}

.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}

.cm-fat-cursor .CodeMirror-line::-moz-selection, .cm-fat-cursor .CodeMirror-line > span::-moz-selection, .cm-fat-cursor .CodeMirror-line > span > span::-moz-selection {
  background: none;
}

.cm-fat-cursor .CodeMirror-line::selection, .cm-fat-cursor .CodeMirror-line > span::selection, .cm-fat-cursor .CodeMirror-line > span > span::selection {
  background: none;
}

.cm-fat-cursor {
  caret-color: #0000;
}

@keyframes blink {
  0% {
  }

  50% {
    background-color: #0000;
  }

  100% {
  }
}

.cm-tab {
  text-decoration: inherit;
  display: inline-block;
}

.CodeMirror-rulers {
  position: absolute;
  inset: -50px 0 0;
  overflow: hidden;
}

.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  position: absolute;
  top: 0;
  bottom: 0;
}

.cm-s-default .cm-header {
  color: #00f;
}

.cm-s-default .cm-quote {
  color: #090;
}

.cm-negative {
  color: #d44;
}

.cm-positive {
  color: #292;
}

.cm-header, .cm-strong {
  font-weight: bold;
}

.cm-em {
  font-style: italic;
}

.cm-link {
  text-decoration: underline;
}

.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-keyword {
  color: #708;
}

.cm-s-default .cm-atom {
  color: #219;
}

.cm-s-default .cm-number {
  color: #164;
}

.cm-s-default .cm-def {
  color: #00f;
}

.cm-s-default .cm-variable-2 {
  color: #05a;
}

.cm-s-default .cm-variable-3, .cm-s-default .cm-type {
  color: #085;
}

.cm-s-default .cm-comment {
  color: #a50;
}

.cm-s-default .cm-string {
  color: #a11;
}

.cm-s-default .cm-string-2 {
  color: #f50;
}

.cm-s-default .cm-meta, .cm-s-default .cm-qualifier {
  color: #555;
}

.cm-s-default .cm-builtin {
  color: #30a;
}

.cm-s-default .cm-bracket {
  color: #997;
}

.cm-s-default .cm-tag {
  color: #170;
}

.cm-s-default .cm-attribute {
  color: #00c;
}

.cm-s-default .cm-hr {
  color: #999;
}

.cm-s-default .cm-link {
  color: #00c;
}

.cm-s-default .cm-error, .cm-invalidchar {
  color: red;
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}

.CodeMirror-matchingtag {
  background: #ff96004d;
}

.CodeMirror-activeline-background {
  background: #e8f2ff;
}

.CodeMirror {
  background: #fff;
  position: relative;
  overflow: hidden;
}

.CodeMirror-scroll {
  height: 100%;
  z-index: 0;
  outline: none;
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  position: relative;
  overflow: scroll !important;
}

.CodeMirror-sizer {
  border-right: 50px solid #0000;
  position: relative;
}

.CodeMirror-vscrollbar, .CodeMirror-hscrollbar, .CodeMirror-scrollbar-filler, .CodeMirror-gutter-filler {
  z-index: 6;
  outline: none;
  display: none;
  position: absolute;
}

.CodeMirror-vscrollbar {
  top: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}

.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-x: scroll;
  overflow-y: hidden;
}

.CodeMirror-scrollbar-filler {
  bottom: 0;
  right: 0;
}

.CodeMirror-gutter-filler {
  bottom: 0;
  left: 0;
}

.CodeMirror-gutters {
  min-height: 100%;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
}

.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  vertical-align: top;
  margin-bottom: -50px;
  display: inline-block;
}

.CodeMirror-gutter-wrapper {
  z-index: 4;
  position: absolute;
  background: none !important;
  border: none !important;
}

.CodeMirror-gutter-background {
  z-index: 4;
  position: absolute;
  top: 0;
  bottom: 0;
}

.CodeMirror-gutter-elt {
  cursor: default;
  z-index: 4;
  position: absolute;
}

.CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: #0000;
}

.CodeMirror-gutter-wrapper ::selection {
  background-color: #0000;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px;
}

.CodeMirror pre.CodeMirror-line, .CodeMirror pre.CodeMirror-line-like {
  font-family: inherit;
  font-size: inherit;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-variant-ligatures: contextual;
  background: none;
  border-width: 0;
  border-radius: 0;
  margin: 0;
  position: relative;
  overflow: visible;
}

.CodeMirror-wrap pre.CodeMirror-line, .CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  z-index: 0;
  position: absolute;
  inset: 0;
}

.CodeMirror-linewidget {
  z-index: 2;
  padding: .1px;
  position: relative;
}

.CodeMirror-rtl pre {
  direction: rtl;
}

.CodeMirror-code {
  outline: none;
}

.CodeMirror-scroll, .CodeMirror-sizer, .CodeMirror-gutter, .CodeMirror-gutters, .CodeMirror-linenumber {
  box-sizing: content-box;
}

.CodeMirror-measure {
  width: 100%;
  height: 0;
  visibility: hidden;
  position: absolute;
  overflow: hidden;
}

.CodeMirror-cursor {
  pointer-events: none;
  position: absolute;
}

.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  z-index: 3;
  position: relative;
}

div.CodeMirror-dragcursors, .CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}

.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}

.CodeMirror-crosshair {
  cursor: crosshair;
}

.CodeMirror-line::-moz-selection, .CodeMirror-line > span::-moz-selection, .CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}

.CodeMirror-line::selection, .CodeMirror-line > span::selection, .CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}

.cm-searching {
  background-color: #ff06;
}

.cm-force-border {
  padding-right: .1px;
}

@media print {
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

.cm-tab-wrap-hack:after {
  content: "";
}

span.CodeMirror-selectedtext {
  background: none;
}

.cm-s-seti.CodeMirror {
  border: none;
  color: #cfd2d1 !important;
  background-color: #151718 !important;
}

.cm-s-seti .CodeMirror-gutters {
  color: #404b53;
  background-color: #0e1112;
  border: none;
}

.cm-s-seti .CodeMirror-cursor {
  border-left: thin solid #f8f8f0;
}

.cm-s-seti .CodeMirror-linenumber {
  color: #6d8a88;
}

.cm-s-seti.CodeMirror-focused div.CodeMirror-selected, .cm-s-seti .CodeMirror-line::selection, .cm-s-seti .CodeMirror-line > span::selection, .cm-s-seti .CodeMirror-line > span > span::selection {
  background: #ffffff1a;
}

.cm-s-seti .CodeMirror-line::-moz-selection, .cm-s-seti .CodeMirror-line > span::-moz-selection, .cm-s-seti .CodeMirror-line > span > span::-moz-selection {
  background: #ffffff1a;
}

.cm-s-seti span.cm-comment {
  color: #41535b;
}

.cm-s-seti span.cm-string, .cm-s-seti span.cm-string-2 {
  color: #55b5db;
}

.cm-s-seti span.cm-number {
  color: #cd3f45;
}

.cm-s-seti span.cm-variable {
  color: #55b5db;
}

.cm-s-seti span.cm-variable-2 {
  color: #a074c4;
}

.cm-s-seti span.cm-def {
  color: #55b5db;
}

.cm-s-seti span.cm-keyword {
  color: #ff79c6;
}

.cm-s-seti span.cm-operator {
  color: #9fca56;
}

.cm-s-seti span.cm-keyword {
  color: #e6cd69;
}

.cm-s-seti span.cm-atom {
  color: #cd3f45;
}

.cm-s-seti span.cm-meta, .cm-s-seti span.cm-tag {
  color: #55b5db;
}

.cm-s-seti span.cm-attribute, .cm-s-seti span.cm-qualifier {
  color: #9fca56;
}

.cm-s-seti span.cm-property {
  color: #a074c4;
}

.cm-s-seti span.cm-variable-3, .cm-s-seti span.cm-type, .cm-s-seti span.cm-builtin {
  color: #9fca56;
}

.cm-s-seti .CodeMirror-activeline-background {
  background: #101213;
}

.cm-s-seti .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: #fff !important;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: monospace;
  font-size: 14px;
}

* {
  box-sizing: border-box;
}

:root {
  --space1by2: .5rem;
  --space1: 1rem;
  --space3by2: 1.5rem;
  --space2: 2rem;
  --space5by2: 2.5rem;
  --darker-grey: #121212;
  --dark-grey: #343434;
  --light-grey: #9a9a9a;
  --bright-red: #e74c3c;
  --white: #fff;
}

.layout {
  background: var(--darker-grey);
  grid-template-rows: var(--space5by2) 1fr var(--space5by2);
  height: 100%;
  display: grid;
}

.layout .header {
  background: var(--darker-grey);
  color: var(--light-grey);
  text-align: center;
  z-index: 1000;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  position: sticky;
  top: 0;
}

.layout .header h1 {
  font-size: 1rem;
}

.layout .header .social {
  justify-content: center;
  align-items: center;
  display: grid;
}

.layout .header .controls {
  grid-template-columns: auto;
  justify-content: center;
  align-items: center;
  display: grid;
}

.layout .header .controls #execute {
  text-transform: uppercase;
  background: var(--bright-red);
  border-color: var(--bright-red);
  color: var(--white);
  letter-spacing: 2px;
  font-size: small;
}

.layout .footer {
  background: var(--darker-grey);
}

.container {
  height: 100%;
  grid-row-gap: 1px;
  grid-column-gap: 1px;
  background: var(--dark-grey);
  min-width: 0;
  min-height: 0;
  grid-template-rows: repeat(2, minmax(5rem, auto)) minmax(5rem, 1fr);
  grid-template-columns: 1fr 1.7fr 1.3fr;
  grid-template-areas: "query compiled-query execution-result"
                       "schema compiled-query execution-result"
                       "resolvers compiled-query execution-result"
                       ". compiled-query execution-result";
  margin-top: .5rem;
  display: grid;
}

#schema-editor {
  grid-area: schema;
}

#resolvers-editor {
  grid-area: resolvers;
}

#query-editor {
  grid-area: query;
}

#compiled-query-viewer {
  grid-area: compiled-query;
}

#execution-result-viewer {
  grid-area: execution-result;
}

.container .grid-item {
  background: var(--dark-grey);
  height: 100%;
  min-width: 0;
  min-height: 0;
}

.container .grid-item .CodeMirror {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.container .grid-item:before {
  z-index: 100;
  background: var(--darker-grey);
  color: var(--light-grey);
  margin-top: -1rem;
  margin-left: 1rem;
  padding: .5rem;
  font-family: monospace;
  position: absolute;
}

#schema-editor:before {
  content: "SCHEMA";
}

#resolvers-editor:before {
  content: "RESOLVERS";
}

#query-editor:before {
  content: "QUERY";
}

#compiled-query-viewer:before {
  content: "COMPILED QUERY";
}

#execution-result-viewer:before {
  content: "EXECUTION RESULT";
}

.container .grid-item .CodeMirror {
  height: 100%;
}

/*# sourceMappingURL=index.23eb0556.css.map */
