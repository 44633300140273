@import "../node_modules/codemirror/lib/codemirror.css";
@import "../node_modules/codemirror/theme/seti.css";

html,
body {
  margin: 0;
  padding: 0;
  font-size: 14px;
  height: 100%;
  font-family: monospace;
}

* {
  box-sizing: border-box;
}

:root {
  --space1by2: 0.5rem;
  --space1: 1rem;
  --space3by2: 1.5rem;
  --space2: 2rem;
  --space5by2: 2.5rem;
  --darker-grey: #121212;
  --dark-grey: #343434;
  --light-grey: #9a9a9a;
  --bright-red: #e74c3c;
  --white: #fff;
}

.layout {
  background: var(--darker-grey);
  display: grid;
  grid-template-rows: var(--space5by2) 1fr var(--space5by2);
  height: 100%;
}

.layout .header {
  background: var(--darker-grey);
  color: var(--light-grey);
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.layout .header h1 {
  font-size: 1rem;
}

.layout .header .social {
  display: grid;
  align-items: center;
  justify-content: center;
}

.layout .header .controls {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: auto;
}

.layout .header .controls #execute {
  font-size: small;
  text-transform: uppercase;
  background: var(--bright-red);
  border-color: var(--bright-red);
  color: var(--white);
  letter-spacing: 2px;
}

.layout .footer {
  background: var(--darker-grey);
}

.container {
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: 1fr 1.7fr 1.3fr;
  grid-template-rows: repeat(2, minmax(5rem, auto)) minmax(5rem, 1fr);
  grid-template-areas:
    "query compiled-query execution-result"
    "schema compiled-query execution-result"
    "resolvers compiled-query execution-result"
    ". compiled-query execution-result";
  height: 100%;
  grid-row-gap: 1px;
  grid-column-gap: 1px;
  background: var(--dark-grey);
  min-width: 0;
  min-height: 0;
}

#schema-editor {
  grid-area: schema;
}
#resolvers-editor {
  grid-area: resolvers;
}
#query-editor {
  grid-area: query;
}
#compiled-query-viewer {
  grid-area: compiled-query;
}
#execution-result-viewer {
  grid-area: execution-result;
}

.container .grid-item {
  background: var(--dark-grey);
  height: 100%;
  min-width: 0;
  min-height: 0;
}

.container .grid-item .CodeMirror {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.container .grid-item:before {
  position: absolute;
  z-index: 100;
  margin-top: -1rem;
  margin-left: 1rem;
  background: var(--darker-grey);
  color: var(--light-grey);
  font-family: monospace;
  padding: 0.5rem;
}

#schema-editor:before {
  content: "SCHEMA";
}
#resolvers-editor:before {
  content: "RESOLVERS";
}
#query-editor:before {
  content: "QUERY";
}
#compiled-query-viewer:before {
  content: "COMPILED QUERY";
}
#execution-result-viewer:before {
  content: "EXECUTION RESULT";
}

.container .grid-item .CodeMirror {
  height: 100%;
}
